<template>
  <div class="add-music">
    <el-form
      :model="musicForm"
      :rules="musicRules"
      ref="musicForm"
      class="demo-musicForm"
      hide-required-asterisk
      label-width="100px"
      label-position="left"
    >
      <el-form-item label="音乐名称" prop="musicName">
        <el-input
          v-model="musicForm.musicName"
          placeholder="请输入音乐名称"
          class="text-input"
        ></el-input>
      </el-form-item>
      <el-form-item label="演唱人" prop="musicSinger">
        <el-input
          v-model="musicForm.musicSinger"
          placeholder="请输入演唱人"
          class="text-input"
        ></el-input>
      </el-form-item>
      <el-form-item label="音乐封面" prop="musicCover">
        <upload-img
          :fixedNumber="[20, 20]"
          fixedName="1:1"
          accept="image/*"
          tip="推荐图片尺寸宽高比1:1"
          uploadName="上传封面"
          @uploadInfo="uploadInfo"
          :fixedFlag="true"
        />
      </el-form-item>
      <el-form-item label="上传音乐" prop="musicUrl">
        <upload-img
          accept="audio/*"
          tip="MP3格式"
          @uploadInfo="uploadAudio"
          @updateProgress="updateProgress"
          @updatePlay="updatePlay"
        />
        <!-- <audio
          src="https://oss.quanjijie.com/audio/794cfa231de24ddf84a705ddb2361f2a.flac"
          controls
          id="audioTag"
          @timeupdate="updateProgress"
        /> -->
      </el-form-item>
      <!-- <el-form-item label="音乐时长" prop="musicDuration">
        <el-input
          v-model="musicForm.musicDuration"
          placeholder="上传时自动获取"
          disabled
          class="text-input"
        ></el-input>
      </el-form-item> -->
      <div class="list-scrollbar">
        <el-form-item
          label="节奏点（可播放音乐使用键盘左右键进行添加~）"
          class="rhythm-box"
        >
          <el-row class="title-row">
            <el-col :span="5">左/右拳</el-col>
            <el-col :span="5">秒/s</el-col>
            <el-col :span="5">公斤/kg</el-col>
            <el-col :span="5">分数</el-col>
            <el-col :span="4">操作</el-col>
          </el-row>
          <el-row
            v-for="(item, index) in musicForm.musicRhythms"
            :key="index"
            class="item-box"
          >
            <el-col :span="5">
              <!-- <el-form-item
                :prop="'musicRhythms.' + index + '.leftOrRight'"
                :rules="musicRules.leftOrRight"
              > -->
              <el-form-item>
                <!-- <el-input
                  v-model="item.leftOrRight"
                  placeholder="请选择左右拳"
                ></el-input> -->
                <el-select
                  v-model="item.leftOrRight"
                  placeholder="请选择左右拳"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <!-- <el-form-item
                :prop="'musicRhythms.' + index + '.hit'"
                :rules="musicRules.hit"
              > -->
              <el-form-item>
                <el-input v-model="item.hit" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <!-- <el-form-item
                :prop="'musicRhythms.' + index + '.forceValue'"
                :rules="musicRules.forceValue"
              > -->
              <el-form-item>
                <el-input
                  v-model="item.forceValue"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <!-- <el-form-item
                :prop="'musicRhythms.' + index + '.fraction'"
                :rules="musicRules.fraction"
              > -->
              <el-form-item>
                <el-input
                  v-model="item.fraction"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <span @click="deleteRhythm(index)">删除</span>
            </el-col>
          </el-row>
          <div class="add-rhythm" @click="addRhythm">
            <img src="../../../assets/add.png" alt="" />
            添加数据
          </div>
        </el-form-item>
      </div>
      <el-form-item label="总分" prop="totalScore">
        <el-input
          v-model="totalScore"
          placeholder="自动计算总分数"
          disabled
          class="text-input"
        ></el-input>
      </el-form-item>
      <el-form-item label="设置星级" class="star-form">
        <el-rate
          v-model="musicForm.starLevel"
          :colors="['#5E83F5', '#5E83F5', '#5E83F5']"
        >
        </el-rate>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="submitForm('musicForm')"
          class="blue-btn"
          :loading="submitLoading"
          >确认创建</el-button
        >
        <el-button @click="resetForm('musicForm')" class="gray-btn"
          >取消</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import UploadImg from "../../../components/UploadImg";
import { addMusic } from "@/api/rhythm";
export default {
  components: { UploadImg },
  data() {
    return {
      musicForm: {
        musicName: "",
        musicSinger: "",
        musicCover: "",
        musicUrl: "",
        starLevel: 1,
        musicRhythms: [
          {
            leftOrRight: "",
            hit: "",
            forceValue: "",
            fraction: "",
          },
        ],
      },
      musicRules: {
        musicName: [
          { required: true, message: "请输入音乐名称", trigger: "blur" },
        ],
        musicSinger: [
          { required: true, message: "请输入音乐演唱人", trigger: "blur" },
        ],
        musicCover: [
          { required: true, message: "请上传音乐封面", trigger: "change" },
        ],
        musicUrl: [
          { required: true, message: "请上传音乐", trigger: "change" },
        ],
        leftOrRight: [
          { required: true, message: "请选择左右拳", trigger: "change" },
        ],
        hit: [
          { required: true, message: "请输入该节奏点时间", trigger: "blur" },
          {
            required: true,
            validator: this.validator.moreThanZero,
            trigger: "blur",
          },
        ],
        forceValue: [
          {
            required: true,
            message: "请输入需要达到的公斤数",
            trigger: "blur",
          },
          {
            required: true,
            validator: this.validator.moreThanZero,
            trigger: "blur",
          },
        ],
        fraction: [
          {
            required: true,
            message: "请输入该节奏点所得分数",
            trigger: "blur",
          },
          {
            required: true,
            validator: this.validator.moreThanZero,
            trigger: "blur",
          },
        ],
      },
      submitLoading: false,
      options: [
        { label: "左拳", value: "left" },
        { label: "右拳", value: "right" },
      ],
      audioElement: null,
      currentTime: 0,
      audioPlay: false,
    };
  },
  created() {},
  mounted() {
    document.addEventListener("keydown", this.handleKeyDown);
  },
  computed: {
    totalScore() {
      // console.log(this.musicForm);
      // const a = 1;
      const a = this.musicForm.musicRhythms.reduce((pre, next) => {
        return (pre += Number(next.fraction));
      }, 0);
      // console.log("a :>> ", a);
      return a === 0 ? 0 : a;
    },
  },
  methods: {
    // 监听键盘事件
    handleKeyDown(event) {
      // console.log("event :>> ", event, this.audioElement);
      if (!this.audioElement && !this.audioPlay) return;
      if (event.key === "ArrowLeft") {
        this.musicForm.musicRhythms.push({
          leftOrRight: "left",
          hit: parseFloat(
            Math.round(this.currentTime * Math.pow(10, 3)) / Math.pow(10, 3)
          ),
          forceValue: 10,
          fraction: 10,
        });
      }
      if (event.key === "ArrowRight") {
        this.musicForm.musicRhythms.push({
          leftOrRight: "right",
          hit: parseFloat(
            Math.round(this.currentTime * Math.pow(10, 3)) / Math.pow(10, 3)
          ),
          forceValue: 10,
          fraction: 10,
        });
      }
    },

    // 获取上传封面
    uploadInfo(info) {
      // console.log("info :>> ", info);
      this.musicForm.musicCover = info;
    },
    // 获取上传音乐
    uploadAudio(info) {
      this.musicForm.musicUrl = info;
      this.audioElement = new Audio(info);
      this.audioElement.addEventListener("loadedmetadata", () => {
        const audioDuration = this.audioElement.duration;
        // console.log(audioDuration);
        // const m = Math.floor(audioDuration / 60);
        // const s = Math.floor(audioDuration % 60);
        this.musicForm.musicDuration = audioDuration;
        // this.musicForm.musicDuration = `${m < 10 ? "0" + m : m}:${
        //   s < 10 ? "0" + s : s
        // }`;
      });
      // audioElement.addEventListener("canplay", (e) => {
      //   console.log("e :>> ", e);
      // });
    },
    updateProgress(time) {
      // console.log("time :>> ", time);
      this.currentTime = time;
    },
    updatePlay(value) {
      console.log("value :>> ", value);
      this.audioPlay = value;
    },
    // 添加节奏点
    addRhythm() {
      this.musicForm.musicRhythms.push({
        leftOrRight: "",
        hit: "",
        forceValue: "",
        fraction: "",
      });
    },
    // 删除节奏点
    deleteRhythm(index) {
      if (this.musicForm.musicRhythms.length === 1) {
        return;
      }
      this.musicForm.musicRhythms.splice(index, 1);
      // console.log("this.mu :>> ", this.musicForm.musicRhythms);
    },
    // 新建音乐
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.submitLoading = true;
          // console.log("object :>> ", this.musicForm);
          // this.musicForm.totalScore = this.totalScore;
          await addMusic(this.musicForm);
          this.$message.success("添加成功！");
          this.$router.go(-1);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      // this.$refs[formName].resetFields();
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.add-music {
  ::v-deep .list-scrollbar {
    // max-height: 40vh;
    padding: 20px 0;
    width: 60%;
    .rhythm-box {
      > .el-form-item__content {
        border: 1px solid #dbdfdf;
      }
    }
    .add-rhythm {
      margin-top: 25px;
      padding: 10px 0;
      background: rgba(94, 131, 245, 0.16);
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 14px;
      color: #5e83f5;
      line-height: 21px;
      cursor: pointer;
      img {
        width: 21px;
        height: 21px;
        margin-bottom: 5px;
      }
    }
    .el-form-item__error {
      // white-space: nowrap;
      padding: 0;
    }
  }
  .text-input {
    width: 200px;
  }
  ::v-deep .title-row {
    background: #f5f6f6;
    border-radius: 2px;
    height: 32px;
    .el-col-4,
    .el-col-5 {
      text-align: center;
      height: 32px;
      font-size: 14px;
      color: #696969;
      line-height: 32px;
    }
  }
  ::v-deep .item-box {
    margin-top: 16px;
    .el-col-5 {
      display: flex;
      align-items: center;
      justify-content: center;
      .el-input {
        width: 130px;
        text-align: center;
        .el-input__inner {
          text-align: center;
          height: 32px;
          line-height: 32px;
        }
        .el-input__icon {
          height: 32px;
          line-height: 32px;
        }
      }
    }
    .el-col-4 {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      span {
        font-size: 16px;
        color: #ee3a3a;
        line-height: 21px;
        cursor: pointer;
      }
    }
  }
  ::v-deep .star-form {
    .el-rate {
      height: 32px;
      line-height: 32px;
      border-radius: 2px;
      border: 1px solid #dbdfdf;
      width: 200px;
      text-align: center;
      .el-rate__item {
        .el-rate__icon {
          font-size: 28px;
        }
        // .el-rate__decimal {
        //   color: #ce3535; /* 修改为需要的颜色 */
        // }
      }
    }
  }
}
</style>
